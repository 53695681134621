<template>
    <v-card align="left" justify="left">
        <v-card-text class="pa-3 font-weight-light white black--text">
            <v-icon class="primary--text lighten-2 pa-2"> mdi-sync </v-icon
            >{{ title }}
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>
